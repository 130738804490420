import React, { ReactNode, memo } from "react";
import { Box } from "./generic/Box";
import { COLORS } from "../constants/colors";
import { Text } from "./generic/Text";
import { IPressableProps } from "./generic/Pressable";

interface IProfileStat extends IPressableProps {
  label?: string;
  value?: string | number | ReactNode;
  icon?: ReactNode;
  isLoading?: boolean;
  textColor?: string;
  onPress?: () => void;
  bg?: string;
  color?: string;
}

export const Stat = memo(
  ({
    isLoading,
    onPress,
    icon,
    textColor,
    color = COLORS.primary,
    label,
    bg = `${COLORS.primary2}11`,
    value,
    ...props
  }: IProfileStat) => {
    return (
      <Box
        py={8}
        br={5}
        bg={bg}
        flex={1}
        flexDirection="row"
        justifyContent="center"
        alignItems={label ? undefined : "center"}
        {...props}
      >
        <Box
          flex={1}
          flexDirection="row"
          pl={label ? 20 : 0}
          alignItems="center"
          justifyContent={label ? undefined : "center"}
        >
          {value !== undefined ? (
            <Box flexDirection="row" alignItems="center">
              <Text
                isLoading={isLoading}
                renderTextWhenLoading={true}
                color={color}
                size={18}
                w={label ? 40 : undefined}
                align="left"
                fontWeight="bold"
              >
                {value ?? 0}
              </Text>
            </Box>
          ) : null}
          {label ? (
            <Box flexDirection="row" center={true}>
              <Text
                isLoading={isLoading}
                color={textColor ?? COLORS.text[700]}
                renderTextWhenLoading={true}
                size={14}
                align="center"
              >
                {label}
              </Text>
            </Box>
          ) : null}

          {icon}
        </Box>
      </Box>
    );
  }
);

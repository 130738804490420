import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Game } from "../components/game/Game";
import { Image } from "../components/generic/Image";
import { Box } from "../components/generic/Box";
import { Stat } from "../components/Stat";
import { TimeCounter } from "../components/generic/TimeCounter";
import dayjs from "dayjs";
import { GAME_SETTINGS } from "../constants/settings";
import { COLORS } from "../constants/colors";
import { getSecondsDuration } from "../util/time";
import { IGameComplete } from "../types/IGameComplete";
import { getWordsForDate } from "../util/word";
import { Container } from "../components/Container";
import { isWeb } from "../util/platform";
import { IntensityEffects } from "../components/IntensityEffects";
import { BannerAdvert } from "../components/ads/BannerAdvert";

interface IGameScreen {
  attemptNumber?: number;
  onComplete: ({ correctWords, failingWord, state }: IGameComplete) => void;
}

function getNumberWithOrdinal(n: number) {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export const GameScreen = ({ onComplete, attemptNumber }: IGameScreen) => {
  const endTimeoutRef = useRef<any>(null);
  const [words, setWords] = useState<string[]>();
  const [currentWord, setCurrentWord] = useState<string>();
  const [survivedCount, setSurvivedCount] = useState<number>(0);
  const [timeEnd, setTimeEnd] = useState<string>(
    dayjs().add(GAME_SETTINGS.SECONDS, "seconds").format()
  );

  const [startingAttemptNumber] = useState(attemptNumber ?? 1);

  const onGameFail = useCallback(() => {
    onComplete({
      state: "fail",
      failingWord: words?.[survivedCount + 1],
      correctWords: words?.slice(0, survivedCount + 1),
    });
  }, [onComplete, words, survivedCount]);

  const newGame = useCallback(
    (newWords?: string[]) => {
      const useWords = newWords ?? words;

      const word = useWords[survivedCount + (currentWord ? 1 : 0)];
      if (!word) {
        onComplete({ correctWords: words, failingWord: "", state: "complete" });
        return;
      }

      setCurrentWord(word);
      setTimeEnd(dayjs().add(GAME_SETTINGS.SECONDS, "seconds").format());

      if (endTimeoutRef.current) {
        clearTimeout(endTimeoutRef.current);
      }

      endTimeoutRef.current = setTimeout(() => {
        onGameFail();
      }, GAME_SETTINGS.SECONDS * 1000);
    },
    [currentWord, onComplete, onGameFail, survivedCount, words]
  );

  const onGamePass = useCallback(() => {
    setSurvivedCount((last) => last + 1);
    newGame();
  }, [newGame]);

  const date = useMemo(() => {
    return dayjs().startOf("day");
  }, []);

  useEffect(() => {
    const words = getWordsForDate(date.toDate());
    setWords(words);
    newGame(words);
  }, [date]);

  return (
    <>
      <Box
        flex={1}
        justifyContent="center"
        alignItems="center"
        w="100%"
        h="100%"
      >
        <Box
          position={isWeb ? "fixed" : "absolute"}
          w="100%"
          h="100%"
          top={0}
          overflow="hidden"
        >
          <Box position="absolute" w="100%" h="100%" top={0} overflow="hidden">
            <IntensityEffects endTimestamp={dayjs(timeEnd).unix() * 1000} />
          </Box>
        </Box>
        <Box
          flex={isWeb ? undefined : 1}
          w="100%"
          justifyContent="center"
          alignItems="center"
        >
          {isWeb ? (
            <>
              <Box justifyContent="center" alignItems="center" mb={50} pt={30}>
                <Image
                  showSpinner={false}
                  isLoadingOverlay={false}
                  w={200}
                  h={70}
                  resizeMode="contain"
                  source={require("../../assets/21-logo.png")}
                />
              </Box>
            </>
          ) : null}
          <Container>
            <Box
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              py={isWeb ? 45 : 0}
              px={30}
            >
              {!isWeb ? (
                <>
                  <Box flex={1} />
                  <Box justifyContent="center" alignItems="center" mb={"20%"}>
                    <Image
                      showSpinner={false}
                      isLoadingOverlay={false}
                      w={200}
                      h={70}
                      resizeMode="contain"
                      source={require("../../assets/21-logo.png")}
                    />
                  </Box>
                </>
              ) : null}
              <Box gap={5} w="100%">
                <Box mb={5} gap={10}>
                  <Box
                    flexDirection="row"
                    gap={10}
                    justifyContent="center"
                    w="100%"
                  >
                    <Stat
                      flex={1}
                      color={"#f5d19c"}
                      value={date.format("DD/MM/YYYY")}
                    ></Stat>
                    <Stat
                      flex={1}
                      label={`attempt`}
                      color={"#f5d19c"}
                      value={`${getNumberWithOrdinal(startingAttemptNumber)}`}
                    ></Stat>
                  </Box>
                  <Box
                    flexDirection="row"
                    gap={10}
                    justifyContent="center"
                    w="100%"
                  >
                    <Stat
                      flex={1}
                      label="seconds"
                      value={
                        <TimeCounter
                          getText={getSecondsDuration}
                          time={timeEnd}
                        />
                      }
                    ></Stat>
                    <Stat
                      flex={1}
                      label="words"
                      color={COLORS.primary2}
                      value={`${survivedCount}`}
                    ></Stat>
                  </Box>
                </Box>

                {currentWord ? (
                  <Game word={currentWord} onGamePass={onGamePass}></Game>
                ) : null}
              </Box>
              <Box flex={1} />
            </Box>
          </Container>
          <Box w="100%" justifyContent="center" alignItems="center">
            <Box w="100%" p={10} maxWidth={500}>
              <BannerAdvert />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

import LottieView from "lottie-react-native";
import React from "react";

import { Box } from "../components/generic/Box";
import { NotificationModal } from "../components/NotificationModal";
import { Container } from "../components/Container";
import { isWeb } from "../util/platform";

interface IEditProfileModalProps {
  onClose?: () => void;
}

export const NotificationScreen = ({ onClose }: IEditProfileModalProps) => {
  return (
    <Box flex={1} justifyContent="center" alignItems="center" w="100%">
      <Box flex={isWeb ? undefined : 1} w="100%">
        {isWeb ? (
          <Box justifyContent="center" alignItems="center" mb={20}>
            <Box>
              <LottieView
                speed={0.8}
                source={require("../../assets/animations/notification.json")}
                autoPlay={true}
                loop={true}
              />
            </Box>
          </Box>
        ) : null}

        <Container>
          <Box justifyContent="center" alignItems="center" flex={1} w="100%">
            <Box
              alignItems="center"
              justifyContent="center"
              py={isWeb ? 10 : 0}
              pt={20}
              w="100%"
              flexDirection="row"
              center={true}
            >
              <NotificationModal onClose={onClose} />
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

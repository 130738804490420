import { memo, ReactNode } from "react";
import { Box } from "./generic/Box";
import { ScrollView, StyleSheet, useWindowDimensions } from "react-native";
import { FadeIn, FadeOutDown } from "react-native-reanimated";
import { isWeb } from "../util/platform";

interface IContainerProps {
  children: ReactNode;
}

export const Container = memo(({ children }: IContainerProps) => {
  const { height: windowHeight } = useWindowDimensions();

  if (!isWeb) {
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        bounces={false}
        style={styles.scroll}
      >
        <Box
          minHeight={windowHeight}
          exiting={FadeOutDown.duration(500)}
          zIndex={9999}
          entering={FadeIn.delay(400).duration(500)}
        >
          {children}
        </Box>
      </ScrollView>
    );
  }

  return (
    <Box flex={1} w="100%" h="100%" justifyContent="center" alignItems="center">
      <Box
        justifyContent="center"
        alignItems="center"
        w="100%"
        h="100%"
        zIndex={1000}
      >
        <Box br={20} p={10} py={10}>
          {children}
        </Box>
      </Box>
    </Box>
  );
});

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
  },
});

import {
  AntDesign,
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import {
  Canvas,
  RoundedRect,
  RadialGradient,
  vec,
} from "@shopify/react-native-skia";
import React, { useCallback, useState } from "react";
import {
  Alert,
  Dimensions,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useSettings } from "../hooks/useSettings";
import { useSettingUpdate } from "../hooks/useSettingsUpdate";
import { Box } from "../components/generic/Box";
import { Text } from "../components/generic/Text";
import { COLORS } from "../constants/colors";
import { SettingsHeader } from "../components/settings/SettingsHeader";
import { SettingsItem } from "../components/settings/SettingsItem";
import { Switch } from "../components/generic/Switch";
import { Modal } from "../components/generic/Modal";
import { RateModal } from "../components/settings/RateModal";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Pressable } from "../components/generic/Pressable";
import { FadeIn, FadeInUp, FadeOutDown } from "react-native-reanimated";
import { NotificationModal } from "../components/NotificationModal";
import { isWeb } from "../util/platform";
import { Container } from "../components/Container";

export interface ILeaderboardEntry {
  position: number;
  name: string;
  points: number;
  avatar: string;
}

interface ISettingsScreenProps {
  onBack?: () => void;
}

export const SettingsScreen = ({ onBack }: ISettingsScreenProps) => {
  const { width, height } = useWindowDimensions();
  const [isRateOpen, setIsRateOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const { reset } = useNavigation();

  const { data: { enableNotifications } = {} } = useSettings();
  const { mutate: updateNotifications } = useSettingUpdate(
    "enableNotifications"
  );

  const handleDisableNotifications = useCallback(() => {
    updateNotifications(false);
  }, []);

  const handleEnableNotifications = useCallback(() => {
    setIsNotificationOpen(true);
  }, []);

  const insets = useSafeAreaInsets();

  return (
    <>
      <Container>
        <ScrollView bounces={false} showsVerticalScrollIndicator={false}>
          <Box
            px={"3%"}
            pt={insets.top}
            exiting={FadeOutDown.duration(500)}
            entering={FadeInUp.delay(400).duration(500)}
          >
            <Box>
              <Box
                justifyContent="space-between"
                alignItems="center"
                p={20}
                flexDirection="row"
                h={60}
              >
                <Pressable
                  onPress={onBack}
                  hitSlop={10}
                  mt={-5}
                  w={20}
                  enableAnimation={true}
                  pressedOpacity={0.9}
                >
                  <Ionicons size={24} color="white" name="arrow-back" />
                </Pressable>
                <Text
                  color={COLORS.white}
                  fontWeight="bold"
                  align="center"
                  size={isWeb ? 18 : 14}
                >
                  Settings
                </Text>
                <Box w={20} />
              </Box>
            </Box>
            <Box p={10} pt={0}>
              <Box>
                <SettingsHeader
                  label="General"
                  icon={
                    <MaterialCommunityIcons
                      name="cog-outline"
                      size={24}
                      color={COLORS.primary2}
                    />
                  }
                />

                <Box>
                  <SettingsItem
                    onPress={() => {
                      setIsRateOpen(true);
                    }}
                    icon={
                      <Feather
                        name="thumbs-up"
                        size={20}
                        color={`${COLORS.primary2}dd`}
                      />
                    }
                    label="Rate 21 Words <3"
                  >
                    <Box flexDirection="row">
                      {[...Array(5)].map((_, i) => (
                        <Box ml={4} opacity={0.8} key={i}>
                          <FontAwesome
                            name="star"
                            size={16}
                            color={`${COLORS.primary3}`}
                          />
                        </Box>
                      ))}
                    </Box>
                  </SettingsItem>
                </Box>
              </Box>
              <Box mt={5}>
                <SettingsHeader
                  label="Notifications"
                  icon={
                    <Feather
                      name="server"
                      size={24}
                      color={`${COLORS.primary2}dd`}
                    />
                  }
                />
                <Box>
                  <SettingsItem
                    icon={
                      <Feather
                        name="bell"
                        size={20}
                        color={`${COLORS.primary2}dd`}
                      />
                    }
                    onPress={
                      enableNotifications
                        ? handleDisableNotifications
                        : handleEnableNotifications
                    }
                    label="Enable notifications"
                  >
                    <Switch
                      value={!!enableNotifications}
                      onPress={
                        enableNotifications
                          ? handleDisableNotifications
                          : handleEnableNotifications
                      }
                    />
                  </SettingsItem>
                </Box>
              </Box>
            </Box>
          </Box>
        </ScrollView>
        <Modal
          isOpen={isNotificationOpen}
          onClose={() => setIsNotificationOpen(false)}
        >
          <Box h="100%" w="100%">
            <Canvas
              style={{ width: "100%", height: "100%", position: "absolute" }}
              pointerEvents="none"
            >
              <RoundedRect r={0} x={0} y={0} width={width} height={height}>
                <RadialGradient
                  c={vec(70, 300 + 100)}
                  r={600}
                  colors={[`${COLORS.primary2}33`, "transparent"]}
                />
              </RoundedRect>

              <RoundedRect r={0} x={0} y={0} width={width} height={height}>
                <RadialGradient
                  c={vec(width, 0)}
                  r={600}
                  colors={[`${COLORS.primary}33`, "transparent"]}
                />
              </RoundedRect>
            </Canvas>
            <NotificationModal
              onClose={() => {
                setIsNotificationOpen(false);
              }}
            />
          </Box>
        </Modal>
      </Container>
      <Modal
        isOpen={isRateOpen}
        onClose={() => setIsRateOpen(false)}
        isClosable={false}
        hasClose={true}
        bg="transparent"
        center={false}
      >
        <RateModal
          onClose={() => {
            setIsRateOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

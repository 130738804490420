import { LoginScreen } from "./LoginScreen";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { GameScreen } from "./GameScreen";
import { EndScreen } from "./EndScreen";
import { IGameComplete } from "../types/IGameComplete";
import { useCurrentGame } from "../hooks/useCurrentGame";
import { useSetCurrentGame } from "../hooks/useSetCurrentGame";
import dayjs from "dayjs";
import * as Haptics from "expo-haptics";
import { NotificationScreen } from "./NotificationScreen";
import { trackEvent } from "../util/analytics";
import { SettingsScreen } from "./SettingsScreen";
import { useSettings } from "../hooks/useSettings";
import { useFonts } from "expo-font";
import {
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_300Light_Italic,
  Poppins_400Regular_Italic,
  Poppins_500Medium_Italic,
  Poppins_600SemiBold_Italic,
  Poppins_700Bold,
  Poppins_800ExtraBold,
} from "@expo-google-fonts/poppins";
import { useAdConsent } from "../hooks/useAdConsent";
import { GradientBackground } from "../components/GradientBackground";
import { useBackHandler } from "../hooks/useBackHandler/useBackHandler";
import { isWeb } from "../util/platform";
import { scheduleNotifications } from "../util/notifications";
import { usePushGameHistory } from "../hooks/useSetGameHistory";
import { useGameHistory } from "../hooks/useGameHistory";

enum GameState {
  START = 1,
  PLAYING = 2,
  END = 3,
  NOTIFICATION = 4,
}

export const HomeScreen = memo(() => {
  const { data: currentGame, isLoading } = useCurrentGame();
  const { mutateAsync: setCurrentGameState } = useSetCurrentGame();
  const [gameState, setGameState] = useState<GameState>(GameState.START);
  const { data: history } = useGameHistory();
  const { mutateAsync: pushHistory } = usePushGameHistory();
  const { data: { hasBeenAskedForNotifications, enableNotifications } = {} } =
    useSettings();

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  useBackHandler(() => {
    return true;
  });

  const resetGame = useCallback(async () => {
    await setCurrentGameState({
      date: dayjs().format("YYYY-MM-DD"),
      correctWords: [],
      isGameComplete: false,
    });
  }, []);

  useAdConsent();

  useEffect(() => {
    scheduleNotifications();
  }, []);

  useEffect(() => {
    if (
      currentGame?.date &&
      currentGame.date !== dayjs().format("YYYY-MM-DD")
    ) {
      resetGame();
      return;
    }

    // REMOVE THIS MAYBE?!
    // // It's the same, do we have any correct words?
    // if (
    //   currentGame?.correctWords?.length > 0 &&
    //   gameState === GameState.START
    // ) {
    //   setGameState(GameState.END);
    // }
  }, [currentGame, gameState, resetGame]);

  const currentAttemptNumber = useMemo(() => {
    const historyAttempts = history?.history.find(
      (a) => a.date === dayjs().format("YYYY-MM-DD")
    )?.attempts;

    return historyAttempts ? historyAttempts + 1 : 1;
  }, [history]);

  const onGameEnd = useCallback(
    async (state: IGameComplete) => {
      const currentDate = dayjs().format("YYYY-MM-DD");

      trackEvent("GAME_END", { ...state, date: currentDate });

      if (state?.correctWords?.length > 0) {
        await pushHistory({
          date: dayjs().format("YYYY-MM-DD"),
          correctCount: state.correctWords?.length || 0,
          count: 1,
          attempts: currentAttemptNumber,
        });
      }

      await setCurrentGameState({
        correctWords: state.correctWords,
        failingWord: state.failingWord,
        isGameComplete: true,
      });

      if (
        !isWeb &&
        !enableNotifications &&
        !hasBeenAskedForNotifications &&
        state.correctWords?.length > 0
      ) {
        setGameState(GameState.NOTIFICATION);
        return;
      }

      setGameState(GameState.END);
    },
    [
      currentAttemptNumber,
      enableNotifications,
      hasBeenAskedForNotifications,
      pushHistory,
      setCurrentGameState,
    ]
  );

  const onNotificationContinue = useCallback(() => {
    setGameState(GameState.END);
  }, []);

  const onContinue = useCallback(() => {
    trackEvent("END_GAME_CONTINUE_PRESSED");

    setGameState(GameState.START);
  }, []);

  const onStart = useCallback(async () => {
    await setCurrentGameState({
      date: dayjs().format("YYYY-MM-DD"),
      correctWords: [],
    });
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);

    trackEvent("START_GAME", { date: dayjs().format("YYYY-MM-DD") });

    setGameState(GameState.PLAYING);
  }, [setCurrentGameState]);

  const [fontsLoaded] = useFonts({
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_300Light_Italic,
    Poppins_400Regular_Italic,
    Poppins_500Medium_Italic,
    Poppins_600SemiBold_Italic,
    Poppins_700Bold,
    Poppins_800ExtraBold,
  });

  return (
    <>
      <GradientBackground />
      {fontsLoaded ? (
        <>
          {!isLoading ? (
            <>
              {!isSettingsOpen ? (
                <>
                  {gameState === GameState.START ? (
                    <LoginScreen
                      isLoading={false}
                      onStart={onStart}
                      onSettingsOpen={() => setIsSettingsOpen(true)}
                      date={dayjs().format("YYYY-MM-DD")}
                    />
                  ) : null}
                  {gameState === GameState.PLAYING ? (
                    <GameScreen
                      onComplete={onGameEnd}
                      attemptNumber={currentAttemptNumber}
                    />
                  ) : null}
                  {gameState === GameState.END ? (
                    <EndScreen
                      onLongPress={resetGame}
                      onContinue={onContinue}
                    />
                  ) : null}
                  {gameState === GameState.NOTIFICATION ? (
                    <NotificationScreen onClose={onNotificationContinue} />
                  ) : null}
                </>
              ) : (
                <SettingsScreen
                  onBack={() => {
                    setIsSettingsOpen(false);
                  }}
                />
              )}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
});

import { memo, useCallback, useEffect, useRef } from "react";
import { Box } from "../components/generic/Box";
import { GAME_SETTINGS } from "../constants/settings";
import { COLORS } from "../constants/colors";
import { useWindowDimensions } from "react-native";
import {
  clamp,
  Easing,
  runOnJS,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSequence,
  withTiming,
} from "react-native-reanimated";
import {
  Canvas,
  RoundedRect,
  RadialGradient,
  vec,
} from "@shopify/react-native-skia";

interface IHeartbeatEffectProps {
  endTimestamp: number;
}
export const IntensityEffects = memo(
  ({ endTimestamp }: IHeartbeatEffectProps) => {
    const pulse = useSharedValue(1);
    const intensity = useSharedValue(15);
    const { height, width } = useWindowDimensions();
    const SIZE = width * 2;

    const isMountedRef = useRef(false);
    useEffect(() => {
      isMountedRef.current = true;
      return () => {
        isMountedRef.current = false;
      };
    }, []);

    const recalculateIntensity = useCallback(() => {
      if (!isMountedRef.current) {
        return;
      }

      const progress =
        1 - (endTimestamp - Date.now()) / (GAME_SETTINGS.SECONDS * 1000);
      console.log({ progress });
      intensity.value = 3500 * (1 - clamp(progress, 0, 0.95));
    }, [endTimestamp, intensity]);

    const onPulseEnd = useCallback(() => {
      recalculateIntensity();
      pulse.value = withSequence(
        withTiming(0.5, {
          duration: intensity.value,
          easing: Easing.bezier(0.25, 0.1, 0.25, 1), // Smoother breathing-like effect
        }),
        withTiming(
          1.2,
          {
            duration: intensity.value,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1), // Matches the inhale and exhale pattern
          },
          (a) => {
            if (a) {
              runOnJS(onPulseEnd)();
            }
          }
        )
      );
    }, [intensity, pulse, recalculateIntensity]);

    useEffect(() => {
      onPulseEnd();
    }, [onPulseEnd]);

    const animatedRadius = useDerivedValue(() => SIZE * pulse.value);

    const opac = useSharedValue(0);
    useEffect(() => {
      opac.value = withSequence(
        withTiming(0, { duration: 500 }),
        withTiming(1, {
          duration: endTimestamp - Date.now(),
          easing: Easing.linear,
        })
      );
    }, [endTimestamp, opac]);

    const containerStyle = useAnimatedStyle(() => {
      return {
        opacity: opac.value,
      };
    });

    return (
      <Box pointerEvents="box-none" isAnimated={true} style={containerStyle}>
        <Box opacity={0.8} pointerEvents="box-none">
          <Canvas
            style={{
              width: width,
              height: height,
              opacity: 0.9,
            }}
            pointerEvents="none"
          >
            <RoundedRect r={0} x={0} y={0} width={width} height={height}>
              <RadialGradient
                c={vec(width, height * 1)}
                r={animatedRadius}
                colors={[`${COLORS.primary}`, "transparent"]}
              />
            </RoundedRect>

            <RoundedRect r={0} x={0} y={0} width={width} height={height}>
              <RadialGradient
                c={vec(0, 0)}
                r={animatedRadius}
                colors={[`${COLORS.primary}`, "transparent"]}
              />
            </RoundedRect>
          </Canvas>
        </Box>
      </Box>
    );
  }
);

const AMPLITUDE_API_KEY = "8a5ad33a1109e3dec411cb601ec8d32b";
import {
  init,
  track as amplitudeTrack,
} from "@amplitude/analytics-react-native";
import { Mixpanel } from "mixpanel-react-native";
import { Platform } from "react-native";
import firebaseAnalytics, { firebase } from "@react-native-firebase/analytics";
import { initializeApp } from "@react-native-firebase/app";

init(AMPLITUDE_API_KEY);

const DEV_MIXPANEL_KEY = "09ad0994305e5965e2037bb905326ee3";
const PROD_MIXPANEL_KEY = "c2440a9f9d27a1d3264e3f4fa17d96b0";

const mixpanel = new Mixpanel(
  __DEV__ ? DEV_MIXPANEL_KEY : PROD_MIXPANEL_KEY,
  false
);

mixpanel.init();

console.log("hmm");
if (Platform.OS === "web") {
  const firebaseConfig = {
    apiKey: "AIzaSyDEQvhJIKQ_333sS8K43RNpg8aVgoD24bg",
    authDomain: "words-ac561.firebaseapp.com",
    projectId: "words-ac561",
    storageBucket: "words-ac561.firebasestorage.app",
    messagingSenderId: "1009776067519",
    appId: "1:1009776067519:web:1ecb484710d6f70301570a",
    measurementId: "G-WT25K55RR7",
  };

  initializeApp(firebaseConfig);
}

export const trackEvent = async (
  eventName: string,
  params?: Record<string, any>
) => {
  try {
    mixpanel.track(eventName, params);
    amplitudeTrack(eventName, params);
    await firebaseAnalytics().logEvent(eventName, params);
  } catch (e) {
    console.log("Error analytics:", e);
  }
};

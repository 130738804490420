import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMutation, useQueryClient } from "react-query";
import { useGameHistory } from "./useGameHistory";
import dayjs from "dayjs";

export interface IGameHistory {
  history: {
    date: string;
    correctCount: number;
    count: number;
    attempts: number;
  }[];
}

export const usePushGameHistory = () => {
  const queryClient = useQueryClient();
  const { data: gameHistory } = useGameHistory();

  return useMutation(
    async (pushedHistory: IGameHistory["history"][0]) => {
      const newGameHistory = {
        ...gameHistory,
        history: [...(gameHistory?.history ?? [])],
      };

      const existingHistoryForDay = newGameHistory?.history.find(
        (history) => history.date === pushedHistory.date
      );

      if (existingHistoryForDay) {
        existingHistoryForDay.correctCount =
          pushedHistory.correctCount > existingHistoryForDay.correctCount
            ? pushedHistory.correctCount
            : existingHistoryForDay.correctCount;

        existingHistoryForDay.attempts = pushedHistory.attempts;

        existingHistoryForDay.count += 1;
      } else {
        newGameHistory.history.push(pushedHistory);
      }

      await AsyncStorage.setItem(
        "game-history",
        JSON.stringify({ history: newGameHistory.history })
      );

      let lastDate = null;
      let streak = 1;

      for (let i = newGameHistory.history.length - 1; i >= 0; i--) {
        const history = newGameHistory.history[i];

        const dif = -dayjs(history.date)
          .hour(6)
          .minute(6)
          .diff(dayjs(lastDate).hour(6).minute(6), "day");

        if (lastDate && dif === 1) {
          streak++;
        } else if (lastDate && dif > 1) {
          break;
        }

        lastDate = history.date;
      }

      await AsyncStorage.setItem(
        "game-streak",
        JSON.stringify({
          streak: { streak, lastDate: dayjs().format("YYYY-MM-DD") },
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.resetQueries("game-history");
      },
    }
  );
};

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect } from "react";
import { trackEvent } from "../util/analytics";

export const useInstallTracking = () => {
  useEffect(() => {
    (async () => {
      const hasTracked = await AsyncStorage.getItem("app_install_tracked");
      if (!hasTracked) {
        trackEvent("app_installed");
        await AsyncStorage.setItem("app_install_tracked", "true");
      }
    })();
  }, []);
};

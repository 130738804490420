import { Linking } from "react-native";
import { isWeb } from "../util/platform";
import { Box } from "./generic/Box";
import { Image } from "./generic/Image";
import { Pressable } from "./generic/Pressable";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const DOWNLOAD_IOS_BUTTON = require("../../assets/app-store.png");
const DOWNLOAD_PLAY_BUTTON = require("../../assets/google-play.png");

export const DownloadAppButtons = () => {
  if (!isWeb) {
    return null;
  }

  return (
    <Box
      gap={20}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Pressable
        mt={20}
        enableAnimation={true}
        onPress={() =>
          Linking.openURL("https://apps.apple.com/gb/app/21-words/id6504304763")
        }
      >
        <Image
          source={DOWNLOAD_IOS_BUTTON}
          showSpinner={false}
          w={140}
          h={60}
          resizeMode="contain"
        />
      </Pressable>
      <Pressable
        mt={20}
        enableAnimation={true}
        onPress={() =>
          Linking.openURL("https://apps.apple.com/gb/app/21-words/id6504304763")
        }
      >
        <Image
          source={DOWNLOAD_PLAY_BUTTON}
          showSpinner={false}
          w={140}
          h={60}
          resizeMode="contain"
        />
      </Pressable>
    </Box>
  );
};

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export const getFormattedTimeDuration = (date: string, from?: undefined) => {
  const now = dayjs(date);
  const then = dayjs(from);
  const diff = dayjs.duration(now.diff(then));

  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();

  let result = "";

  if (hours > 0) {
    result += `${hours}h `;
  }

  result += `${minutes}m `;

  result += `${seconds}s`;

  return result;
};

export const getSecondsDuration = (date: string, from?: string) => {
  const now = dayjs(from).utc();
  const then = dayjs(date).utc();
  const diff = dayjs.duration(then.diff(now));

  const seconds = diff.seconds();

  return `${Math.max(0, seconds)}`;
};

export const getFormattedSecondDuration = (totalSeconds?: number | string) => {
  if (!totalSeconds) return "1m";

  const diff = dayjs.duration(+totalSeconds, "seconds");

  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();

  let result = "";

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (minutes > 0) {
    result += `${minutes}m `;
  }

  result += `${seconds}s`;

  return result.trim();
};

export const timeAgo = (dateString: string) => {
  const date = dayjs(dateString);
  const diff = dayjs().diff(date);
  const dur = dayjs.duration(diff);

  if (dur.asSeconds() < 60) {
    const seconds = Math.floor(dur.asSeconds());
    return `${seconds + 1} second${seconds + 1 === 1 ? "" : "s"} ago`;
  } else {
    return date.fromNow();
  }
};

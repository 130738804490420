import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Alert, Linking } from "react-native";
import { COLORS } from "../constants/colors";
import dayjs from "dayjs";
import { sample } from "lodash";

export async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      // sound: "notification.wav",
      lightColor: COLORS.primary3,
    });

    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();

    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync({
        ios: {
          allowAlert: true,
          allowBadge: true,
          allowSound: true,
          allowAnnouncements: true,
        },
      });

      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      Alert.alert(
        `Permission was not granted! (${finalStatus})`,
        "To enable push notifications, please go to settings and turn on notifications",
        [
          { text: "Cancel", onPress: () => {} },
          { text: "Open settings", onPress: () => Linking.openSettings() },
        ]
      );

      throw "Notification permission not granted";
    }

    token = (
      await Notifications.getExpoPushTokenAsync({
        projectId: "5a31428c-3690-4b7a-a90b-aef6bed23ce6",
      })
    )?.data;
  } else {
    Alert.alert(
      "Can't enable notifications",
      "Notifications don't work on an emulator!"
    );
    throw "Must use physical device for Push Notifications";
  }

  return token;
}

const NOTIFICATIONS = [
  {
    title: "New Puzzle Alert!",
    body: "Your daily 21 Words puzzle is ready. Can you solve today's challenges?",
  },
  {
    title: "Time to Unscramble!",
    body: "A fresh set of scrambled words awaits you. Play today's 21 Words puzzle now!",
  },
  {
    title: "Brain Teaser Time!",
    body: "Keep your mind sharp with today's 21 Words puzzle. Start unscrambling!",
  },
  {
    title: "Daily Challenge Awaits!",
    body: "New words, new challenge! Dive into today's 21 Words puzzle.",
  },
  {
    title: "Word Master Challenge!",
    body: "Ready to test your skills? Today's 21 Words puzzle is here. Unscramble now!",
  },
  {
    title: "New Words to Solve!",
    body: "Today's 21 Words puzzle is live. How many words can you unscramble?",
  },
  {
    title: "Puzzle of the Day!",
    body: "The daily 21 Words puzzle is ready. See if you can solve all the words!",
  },
  {
    title: "Daily Word Fun!",
    body: "Your daily dose of word fun is here. Play today's 21 Words puzzle!",
  },
  {
    title: "Start Your Word Challenge!",
    body: "A new 21 Words puzzle is available. Unscramble the words and challenge your mind!",
  },
  {
    title: "New Day, New Puzzle!",
    body: "Tackle today's 21 Words puzzle and see how many words you can solve!",
  },
];

const getNotificationText = () => {
  return {
    title: sample(NOTIFICATIONS).title,
    body: sample(NOTIFICATIONS).body,
  };
};

export const scheduleNotifications = async () => {
  await Notifications.cancelAllScheduledNotificationsAsync();

  const notification = getNotificationText();
  await Notifications.scheduleNotificationAsync({
    content: {
      title: notification.title,
      body: notification.body,
    },
    trigger: {
      channelId: "default",
      // repeats: true,
      type: Notifications.SchedulableTriggerInputTypes.DAILY,
      hour: 13,
      minute: 0,
    },
  });

  const scheduled = await Notifications.getAllScheduledNotificationsAsync();
};
